import { User, type Property } from "@interfaces";
import React, {
  type Dispatch,
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { getPrivatePropertyListAPI, searchPropertyListAPI } from "./properties";
import { getProfileAPI } from "./authentication";
import { defaultUser } from "defaults/authentication.defaults";
import { useMediaQuery } from "@mui/material";

interface AppContext {
  user: User | undefined;
  setUser: Dispatch<User>;
  loading: boolean;
  setLoading: (boolean: boolean) => void;
  isLoggedIn: boolean;
  isAdmin: boolean;
  setIsAdmin: (boolean: boolean) => void;
  properties: Property[];
  privateProperties: Property[];
  // fetchPrivateProperties: () => Promise<void>;
  showTopNavDropdown: boolean;
  detailProperty: Property | null;
  setShowTopNavDropdown: (boolean: boolean) => void;
  setDetailProperty: Dispatch<Property>;
  isMobile: boolean;
}

interface AppProviderProps {
  children: ReactNode;
}

const defaultContext = {
  user: defaultUser,
  setUser: () => {},
  loading: false,
  setLoading: () => {},
  setIsAdmin: () => {},
  isLoggedIn: false,
  isAdmin: false,
  properties: [],
  privateProperties: [],
  // fetchPrivateProperties: async () => {},
  showTopNavDropdown: false,
  detailProperty: null,
  setShowTopNavDropdown: () => {},
  setDetailProperty: () => {},
  isMobile: false,
};

export const AppContext = createContext<AppContext>(defaultContext || null);

export const useApp = () => useContext(AppContext);

export const AppContextProvider = ({ children }: AppProviderProps) => {
  const isMobile = !useMediaQuery("(min-width: 769px)");
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [properties, setProperties] = useState<Property[]>([]);
  const [privateProperties, setPrivateProperties] = useState<Property[]>([]);
  const [detailProperty, setDetailProperty] = useState<Property | null>(null);
  const [showTopNavDropdown, setShowTopNavDropdown] = useState(false);
  const [user, setUser] = useState<User | undefined>(undefined);

  useEffect(() => {
    setShowTopNavDropdown(false);
    setIsLoggedIn(localStorage.hasOwnProperty("token"));
    let timeOutId: NodeJS.Timeout | undefined;

    const fetchPublicProperties = async () => {
      if (!location.pathname.startsWith("/property")) return;
      try {
        const res = await searchPropertyListAPI(searchParams);
        if (res) setProperties(res.properties);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    timeOutId = setTimeout(() => {
      fetchPublicProperties();
    }, 1200);
    return () => {
      clearTimeout(timeOutId);
    };
  }, [location.pathname, searchParams]);

  useEffect(() => {
    const fetchAndSetUser = async () => {
      if (localStorage.hasOwnProperty("token")) {
        const res = await getProfileAPI();
        if (res) setUser(res);
      }
    };

    fetchAndSetUser();
  }, [location.pathname]);

  return (
    <AppContext.Provider
      value={{
        isMobile,
        user,
        setUser,
        loading,
        setLoading,
        isLoggedIn,
        isAdmin,
        setIsAdmin,
        properties,
        privateProperties,
        // fetchPrivateProperties,
        detailProperty,
        showTopNavDropdown,
        setShowTopNavDropdown,
        setDetailProperty,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
