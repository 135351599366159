import { Avatar, Box, Button, Typography, useTheme } from "@mui/material";
import { usePropertyCreation } from "services/property-creation-context";
import { EnergyBar } from "components";
import {
  formatDate,
  formatPrice,
  getEnergyGrade,
  getLocalizedLabels,
} from "services";
import { LABELS } from "../../constants";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "scss/component/custom.css";

import {
  FaLayerGroup,
  FaThLarge,
  FaExternalLinkAlt,
  FaDoorOpen,
  FaBath,
  FaCalendarCheck,
  FaBuilding,
  FaHome,
  FaCar,
  FaFileSignature,
  FaCheckCircle,
  FaMapMarkerAlt,
} from "react-icons/fa";
import { type Property } from "@interfaces";
import { ImageCarousel } from "./ImageCarousel";
import { PropertyImageList } from "components/shared/form/ImageDisplay";
import { useParams } from "react-router-dom";
import { usePropertyEdit } from "services/property-edit-context";
import { PropertySummaryLabel } from "../Step12/PropertySummaryLabel";
import { PropertySummaryValue } from "../Step12/PropertySummaryValue";
import { AddresSummary } from "../Step12/AdressSummary";
import { AttachMoney, LocationOn } from "@mui/icons-material";
import { PropertySummarySection } from "../Step12/PropertySummarySection";
import { StepTwelveLabel } from "../Step12/StepTwelveLabel";

export const StepTenForm = ({
  submitUpdatePropertyForm,
}: {
  submitUpdatePropertyForm?: () => Promise<void>;
}) => {
  const { id } = useParams();
  const labels = getLocalizedLabels(LABELS);
  const { customColors } = useTheme();
  const { property, previewImages, setCurrentStep } = id
    ? usePropertyEdit()
    : usePropertyCreation();

  const displayBooleanFields = (arrayOfFields: Array<keyof Property>) => {
    const values = arrayOfFields.map((field) => property[field]);
    if (!values.includes(true) && !arrayOfFields.includes("refurbished")) {
      return " Keine Angabe";
    }
    const returnString = arrayOfFields
      .filter((field) => property[field])
      .map((field) => labels[field])
      .join(", ");
    return returnString;
  };

  const detailKeyList = [
    "floor",
    "living_space",
    "plot_area",
    "room_count",
    "bathroom_count",
    "free_from",
    "flat_type",
    "house_type",
    "garage_count",
    "state_of_development",
  ];
  const detailLabelList = [
    "Stockwerk / Anzahl der Geschosse",
    "Quadratmeter",
    "Gründstück",
    "Zimmer",
    "Badezimmer",
    "Frei ab",
    "Wohnungsart",
    "Haustyp",
    "Garage",
    "Erschließungszustand",
  ];

  const furnishingKeyList = [
    "patio",
    "balcony",
    "rooftop_terrace",
    "winter_garden",
    "garden",
    "elevator",
    "full_bath",
    "shower_room",
    "sauna",
    "swimming_pool",
    "fitted_kitchen",
    "chimney",
    "barrier_free",
  ];
  const furnishingLabelList = [
    "Terrasse",
    "Balkon",
    "Dachterasse",
    "Wintergarten",
    "Garten",
    "Fahrstuhl",
    "Vollbad",
    "Duschbad",
    "Sauna",
    "Pool/Schwimmbad",
    "Einbauküche",
    "Kamin",
    "Barrierefrei",
  ];

  const detailIconList = [
    <FaLayerGroup />,
    <FaThLarge />,
    <FaExternalLinkAlt />,
    <FaDoorOpen />,
    <FaBath />,
    <FaCalendarCheck />,
    <FaBuilding />,
    <FaHome />,
    <FaCar />,
    <FaFileSignature />,
  ];

  const styles = {
    row: {
      padding: "1rem 2rem",
      borderBottom: "4px solid #000",
    },
    sectionTitle: {
      fontSize: { xs: "1.25rem", sm: "1.5rem" },
      mb: "1rem",
    },
    detailOfProperty: {
      width: "50%",
      mb: "2rem",
      display: "flex",
      flexDirection: "column",
      // fontSize: "1.1rem",
    },
    subtitleSection: {
      mb: "1rem",
      minWidth: "300px",
      wordWrap: "break-word",
      flex: 1,
    },
    energySection: {
      mb: "1rem",
      mr: "1.5rem",
      fontSize: { xs: ".9rem", sm: "1.2rem" },
    },
  };

  return (
    <>
      <Box
        sx={{
          ...styles.row,
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          // border: "4px solid orange",
        }}
      >
        <Typography sx={styles.sectionTitle}>
          Übersicht: {property.title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "1rem",
          }}
        >
          <Box
            sx={{
              width: "250px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <PropertySummaryLabel value={"Anschrift"} />
            </Box>
            <AddresSummary
              line1={`${property.street}, ${property.house_number}`}
              line2={`${property.post_code}, ${property.city}`}
              line3={property.country}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "200px",
            }}
          >
            <PropertySummarySection
              label={
                property.transaction_type === "sell" ? "Kaufpreis" : "Mietpreis"
              }
              value={
                property.transaction_type === "sell"
                  ? formatPrice(property.price || 0)
                  : formatPrice(property.base_rent || 0)
              }
            />
          </Box>
          <Box sx={{ flex: 1, minWidth: "300px", maxWidth: "600px" }}>
            <PropertySummaryLabel value={"Details"} />
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {detailKeyList.map((key, idx) => {
                const freeFromValue = property.free_from_date
                  ? property.free_from_date
                  : property.free_from;

                return (
                  property[key as keyof Property] && (
                    <Box key={key} sx={styles.detailOfProperty}>
                      <b>
                        {detailIconList[idx]}
                        {` ${detailLabelList[idx]}: ${
                          key !== "free_from"
                            ? (property[key as keyof Property] as any)
                            : freeFromValue
                        }`}
                      </b>
                    </Box>
                  )
                );
              })}
            </Box>
          </Box>
          <Box sx={{ flex: 1, minWidth: "300px" }}>
            <StepTwelveLabel value={"Ausstattung"} />
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {furnishingKeyList.map((key, idx) => {
                return (
                  property[key as keyof Property] && (
                    <Box
                      key={key}
                      sx={{
                        // minWidth: "60px",
                        minWidth: {
                          xs: "40%",
                          sm: "0",
                        },
                        marginInline: ".2rem",
                      }}
                    >
                      {
                        <b>
                          <FaCheckCircle /> {furnishingLabelList[idx]}{" "}
                        </b>
                      }
                    </Box>
                  )
                );
              })}
            </Box>
          </Box>
        </Box>
        <Button
          sx={{
            textTransform: "none",
            width: { xs: "100%", sm: "50%" },
            alignSelf: "center",
            mt: { xs: "1rem", sm: "0" },
          }}
          onClick={!id ? () => setCurrentStep(10) : submitUpdatePropertyForm}
        >
          {!id ? "Buchung abschließen" : "Änderungen veröffentlichen"}
        </Button>
      </Box>
      <Box
        sx={{
          ...styles.row,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography sx={styles.sectionTitle}>Beschreibung</Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "1rem",
            maxWidth: { xs: "100%", sm: "90%" },
          }}
        >
          {property.description && (
            <Box sx={styles.subtitleSection}>
              <StepTwelveLabel value={"Allgemein"} />
              <Typography>{property.description}</Typography>
            </Box>
          )}
          {property.description_of_location && (
            <Box sx={styles.subtitleSection}>
              <StepTwelveLabel value={"Zur Lage"} />
              <Typography>{property.description_of_location}</Typography>
            </Box>
          )}
          {property.description_of_equipment && (
            <Box sx={styles.subtitleSection}>
              <StepTwelveLabel value={"Zur Ausstattung"} />
              <Typography>{property.description_of_equipment}</Typography>
            </Box>
          )}
        </Box>
      </Box>

      <Box sx={{ ...styles.row, borderBottom: "none" }}>
        <Typography sx={styles.sectionTitle}>
          Bausubstanz & Energieausweis
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <Box>
            <Box sx={styles.energySection}>
              <StepTwelveLabel value={"Objektzustand"} />
              {displayBooleanFields(["old_building", "new_building"])}
              &nbsp;
              {displayBooleanFields([
                "renovated",
                "refurbished",
                "in_need_of_renovation",
              ])}
              &nbsp;({property.year_of_construction})
            </Box>
            {property.energy_certificate && (
              <Box sx={styles.energySection}>
                <StepTwelveLabel value={"Energieausweis"} />
                {property.energy_certificate}
              </Box>
            )}
            {property.energy_need && (
              <Box sx={styles.energySection}>
                <StepTwelveLabel value={"Energiebedarf"} />
                {property.energy_need} kWh/(m^2*a)
              </Box>
            )}
          </Box>
          <Box>
            {
              <Box sx={styles.energySection}>
                <StepTwelveLabel value={"Wesentliche Energieträger"} />
                {displayBooleanFields([
                  "oil",
                  "gas",
                  "district_heating",
                  "electric",
                  "pellets",
                  "solar",
                ])}
              </Box>
            }
            {property.energy_card_type && (
              <Box sx={styles.energySection}>
                <StepTwelveLabel value={"Ausweisart"} />
                {property.energy_card_type}
              </Box>
            )}

            {property.energy_need && (
              <Box sx={styles.energySection}>
                <StepTwelveLabel value={"Energieeffizienz-Klasse"} />
                {getEnergyGrade(property.energy_need)}
              </Box>
            )}
          </Box>
        </Box>
        <EnergyBar
          score={property.energy_need}
          grade={getEnergyGrade(property.energy_need)}
        />
      </Box>
      <Box
        sx={{
          ...styles.row,
        }}
      >
        <Typography sx={styles.sectionTitle}>Bilder</Typography>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexWrap: "wrap",
            gap: ".5rem",
            transform: { xs: "scale(0.95)", sm: "scale(1)" },
            justifyContent: "center",
          }}
        >
          {previewImages &&
            previewImages.map((image) => {
              if (image.previewUrl)
                return (
                  <Avatar
                    key={image.id}
                    src={image.previewUrl}
                    sx={{
                      // width: "45%",
                      flex: 1,
                      minWidth: {
                        xs: "min(45%, 200px)",
                        sm: "min(45%, 300px)",
                      },
                      maxWidth: {
                        xs: "min(45%, 200px)",
                        sm: "min(45%, 300px)",
                      },
                      height: { xs: "auto" },
                      borderRadius: 0,
                      border: "1px solid #000",
                    }}
                  />
                );
            })}
        </Box>
      </Box>
    </>
  );
};
