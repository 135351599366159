import { Box, Tooltip, Typography } from "@mui/material";
import { PropertySummaryValue } from "./PropertySummaryValue";

export const AddresSummary = ({
    line1,
    line2,
    line3,
}: {
    line1: string;
    line2: string;
    line3: string;
}) => {
    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <PropertySummaryValue value={line1} />
            <PropertySummaryValue value={line2} />
            <PropertySummaryValue value={line3} />
        </Box>
    );
};
