import { Property } from "@interfaces";
import { Box } from "@mui/system";

export const ImageDisplay = ({ imageUrl }: { imageUrl: string }) => {
    return (
        <div>
            <img src={imageUrl} alt="property-preview" />
        </div>
    );
};

export const PropertyImageList = ({
    property,
}: {
    property: Property;
}): JSX.Element => {
    const images = property.images.map((image) => (
        <ImageDisplay key={property.id} imageUrl={image.url} />
    ));
    return (
        <Box
            sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
        >
            {images}
        </Box>
    );
};
