import { useSearchParams } from "react-router-dom";
import { Box, Typography, Badge } from "@mui/material";

type SidebarItemProps = {
    Icon: React.FC;
    label: string;
    paramName: string;
    paramValue: string;
    count?: number;
    isSelected?: boolean;
};

export const SidebarItem = ({
    Icon,
    label,
    paramName,
    paramValue,
    count,
    isSelected: isSelected = false,
}: SidebarItemProps) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const updateParams = () => {
        searchParams.delete("messageId");
        const currentParams = searchParams;
        if (paramValue !== "") currentParams.set(paramName, paramValue);
        else currentParams.delete(paramName);
        setSearchParams(currentParams);
    };

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                paddingBlock: ".5rem",
                pl: ".5rem",
                "&:hover": {
                    backgroundColor: "rgba(0,0,0,0.05)",
                    cursor: "pointer",
                },
                "& .MuiBadge-root": {
                    marginRight: "24px",
                },
                "& .MuiBadge-badge": {
                    top: 4,
                    right: -8,
                    maxWidth: "8px",
                    fontSize: "8px",
                    borderRadius: "999px",
                    border: "2px solid #fff",
                },
            }}
            onClick={updateParams}
        >
            {Icon && <Icon />}
            <Badge
                badgeContent={count}
                color="primary"
                sx={{ alignItems: "center" }}
            >
                <Typography
                    sx={{
                        fontSize: "14px",
                        ml: "1rem",
                        fontWeight: isSelected ? "bold" : "normal",
                    }}
                >
                    {label}
                </Typography>
            </Badge>
        </Box>
    );
};
