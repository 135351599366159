import moment from "moment";

export const getLocalizedLabels = (labels: any): any => {
    const currentLanguage = localStorage.getItem("language") || "de";
    const translatedLabels: any = {};
    Object.keys(labels).forEach(
        (key) => (translatedLabels[key] = labels[key][currentLanguage]),
    );
    return translatedLabels;
};

// export const setLanguage = (language) => {
//     localStorage.setItem("language", language);
//     window.location.reload();
// };

// // format price for integers
export const formatPrice = (inp: number | string | null | undefined) => {
    const euroSymbol = "\u20AC";
    const parsedInput = Number(inp);

    if (isNaN(parsedInput)) return `0,00 ${euroSymbol}`;

    const inputString = parsedInput.toFixed(0).replace(".", ",");
    const partBeforeComma = inputString.split(",")[0];

    let formattedString = inputString;
    if (parsedInput > 9999) {
        formattedString =
            partBeforeComma.replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
            inputString.slice(partBeforeComma.length);
    }

    return `${formattedString} ${euroSymbol}`;
};

export const formatPackagePrice = (inp: number | string | null | undefined) => {
    const euroSymbol = "\u20AC";
    const parsedInput = Number(inp);

    if (isNaN(parsedInput)) return `0,00 ${euroSymbol}`;

    const inputString = parsedInput.toFixed(2).replace(".", ",");

    return `${inputString} ${euroSymbol}`;
};

export const formatDate = (dateString: string, shortenYear?: boolean) => {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();

    const parsedMonth = month < 10 ? `0${month}` : month;
    const parsedDay = day < 10 ? `0${day}` : day;
    const parsedYear = shortenYear ? year.toString().slice(2) : year;
    return `${parsedDay}.${parsedMonth}.${parsedYear}`;
};

export const formatDateTime = (
    dateTimeString: string,
    shortenYear?: boolean,
) => {
    const dateTimeObj = moment(dateTimeString);
    const year = dateTimeObj.year();
    const parsedYear = shortenYear ? year.toString().slice(2) : year;
    const month =
        dateTimeObj.month() < 9
            ? `0${dateTimeObj.month() + 1}`
            : dateTimeObj.month();
    const date =
        dateTimeObj.date() < 10 ? `0${dateTimeObj.date()}` : dateTimeObj.date();

    const hours =
        dateTimeObj.hours() < 10
            ? `0${dateTimeObj.hours()}`
            : dateTimeObj.hours();
    const minutes =
        dateTimeObj.minutes() < 10
            ? `0${dateTimeObj.minutes()}`
            : dateTimeObj.minutes();
    return `${hours}:${minutes} Uhr am ${date}.${month}.${parsedYear}`;
};

export const getEnergyGrade = (energyConsumption: number | null): string => {
    if (!energyConsumption) return "";
    let grade: string = "";
    if (energyConsumption >= 0 && energyConsumption < 30) grade = "A+";
    if (energyConsumption >= 30 && energyConsumption < 50) grade = "A";
    if (energyConsumption >= 50 && energyConsumption < 75) grade = "B";
    if (energyConsumption >= 75 && energyConsumption < 100) grade = "C";
    if (energyConsumption >= 100 && energyConsumption < 130) grade = "D";
    if (energyConsumption >= 130 && energyConsumption < 160) grade = "E";
    if (energyConsumption >= 160 && energyConsumption < 200) grade = "F";
    if (energyConsumption >= 200 && energyConsumption < 250) grade = "G";
    if (energyConsumption >= 250) grade = "H";
    return grade;
};
