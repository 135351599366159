import moment from "moment";

export const getCurrentDateDiff = (date: string): number => {
    const currentDate = moment();
    const creationDate = moment(date);
    const diffDays = currentDate.diff(creationDate, "days");
    return diffDays;
};

export const getCurrentYearDiff = (date: string): number => {
    const currentDate = moment();
    const creationDate = moment(date);
    const diffYears = currentDate.diff(creationDate, "years");
    return diffYears;
};

export const formatForDto = (dateString: string | null) => {
    if (!dateString) return;
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
};

export const isLessThanTwoMinutesInThePast = (
    date?: string | null,
): boolean => {
    if (!date) return false;
    const currentDate = moment();
    const givenDate = moment(date);
    const diffMinutes = currentDate.diff(givenDate, "minutes");
    return diffMinutes < 2;
};
