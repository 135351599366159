import "./energy_bar.css";
import { Box, Tooltip } from "@mui/material";

export const EnergyBar = ({
    score,
    grade,
}: {
    score: number | null;
    grade: string | null;
}) => {
    if (!score || !grade) return null;
    const getPercent = () => {
        const scoreForCalc = score > 285 ? 291 : score;
        const percent = (scoreForCalc * 100) / 291;
        return percent;
    };

    return (
        <div className="energy_container">
            <Box
                className="pointer"
                sx={{
                    left: getPercent() - 0.2 + "%",
                    transform: "translate(-50%, -1rem)",
                    "&:before": {
                        content: '""',
                        position: "absolute",
                        height: "100px",
                        bottom: "-80px",
                        borderLeft: "4px solid #000",
                        left: "50%",
                    },

                    // "&:before": {
                    //     content: "",
                    //     position: "absolute",
                    //     height: "90px",
                    //     bottom: "-77.5px",
                    //     left: 0,
                    //     borderLeft: "4px solid #000",
                    // },
                }}
            >
                {score} kWh / (m<sup>2</sup>a) <br />
                {/* Energieeffizienz-Klasse: {grade} */}
            </Box>
            <div className="energy_bar">
                <div className="bar_color before_bar"></div>
                <div className="bar_color a_energy_bar_plus">A+</div>
                <div className="bar_color a_energy_bar">A</div>
                <div className="bar_color b_energy_bar">B</div>
                <div className="bar_color c_energy_bar">C</div>
                <div className="bar_color d_energy_bar">D</div>
                <div className="bar_color e_energy_bar">E</div>
                <div className="bar_color f_energy_bar">F</div>
                <div className="bar_color g_energy_bar">G</div>
                <div className="bar_color h_energy_bar">H</div>
            </div>
            <div className="box_label">
                <div className="bar_label before_bar_label">0</div>
                <div className="bar_label a_plus_label">30</div>
                <div className="bar_label a_label">50</div>
                <div className="bar_label b_label">75</div>
                <div className="bar_label c_label">100</div>
                <div className="bar_label d_label">130</div>
                <div className="bar_label e_label">160</div>
                <div className="bar_label f_label">200</div>
                <div className="bar_label g_label">250</div>
                <div className="bar_label h_label">300</div>
            </div>
        </div>
    );
};
