import { Box } from "@mui/material";
import { propertyFormStyles } from "components/styles";
import { usePropertyCreation } from "services/property-creation-context";
import { TwoColumnForm } from "components";
import { getLocalizedLabels } from "services";
import { LABELS } from "../../constants";
import { leftInputs, rightInputs } from "./step-3-inputs";
import { DisplayInput } from "components/shared/form/DisplayInput";
import { useParams } from "react-router-dom";

export const StepThreeForm = () => {
    const labels = getLocalizedLabels(LABELS);
    const { id } = useParams();
    return (
        <Box sx={propertyFormStyles.centeredContainer}>
            <TwoColumnForm
                leftSide={
                    <Box>
                        {leftInputs.map((input) => (
                            <DisplayInput
                                key={input.name}
                                {...input}
                                disabled={
                                    id !== undefined &&
                                    input.type !== "checkbox"
                                }
                            />
                        ))}
                    </Box>
                }
                rightSide={
                    <Box>
                        {rightInputs.map((input) => (
                            <DisplayInput
                                key={input.name}
                                {...input}
                                disabled={
                                    id !== undefined &&
                                    input.type !== "checkbox"
                                }
                            />
                        ))}
                    </Box>
                }
            />
        </Box>
    );
};
