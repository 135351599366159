import { Dialog, Box, Typography, Button, DialogContent } from "@mui/material";
import { buttonStyles } from "components/styles";

export interface DisplayDialogProps {
  id: string;
  isOpen: boolean;
  title?: string;
  subtitle?: string;
  confirmElement: JSX.Element | null;
  onClose: () => void;
  content?: JSX.Element | false;
}

export const defaultDisplayDialog: DisplayDialogProps = {
  isOpen: false,
  id: "",
  title: "",
  subtitle: "",
  confirmElement: null,
  onClose: () => {},
  content: <></>,
};

export const DisplayDialog = ({
  isOpen,
  onClose,
  id,
  title,
  subtitle,
  confirmElement,
  content,
}: DisplayDialogProps) => {
  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogContent>
        <Box
          sx={{
            // minWidth: "400px",
            padding: "2rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {title && (
            <Box>
              <Typography>{title}</Typography>
            </Box>
          )}
          <hr></hr>
          {subtitle && (
            <Box>
              <Typography variant="caption">{subtitle}</Typography>
            </Box>
          )}
          {content && content}
          <Box
            sx={{
              display: "flex",
              mt: "1rem",
            }}
          >
            {confirmElement}
            {!confirmElement && (
              <Button
                sx={{
                  ...buttonStyles.secondary,
                  mr: 0,
                }}
                onClick={onClose}
              >
                Abbrechen
              </Button>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
