import { Tooltip, Typography } from "@mui/material";
import React from "react";

export const PropertyStatusBadge = ({
  status,
  tooltipText,
  content,
}: {
  status: "active" | "inactive" | "pending";
  content: string | React.ReactNode;
  tooltipText?: string | React.ReactNode;
}) => {
  const getBackgroundColor = (
    status: "active" | "inactive" | "pending"
  ): string => {
    if (status === "active") {
      return "#4caf50";
    } else if (status === "inactive") {
      return "#f44336";
    }
    return "#ff9800";
  };

  return (
    <Tooltip title={tooltipText}>
      <Typography
        sx={{
          padding: "1rem",
          backgroundColor: getBackgroundColor(status),
          color: "#fff",
          width: "150px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: ".5rem",
        }}
      >
        {content}
      </Typography>
    </Tooltip>
  );
};
