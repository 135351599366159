export const propertyFormStyles = {
  centeredContainer: {
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "1rem",
    boxSizing: "border-box",
  },
  buttonRow: {
    width: "90%",
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  buttonWrapper: {
    marginInline: "1rem",
    flex: 1,
    minWidth: "200px",
  },

  navigationRow: {
    display: "flex",
    flexWrap: "wrap",
    gap: '1rem',
    justifyContent:"center"

  },

  twoColumnForm: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    width: "100%",
    // border: "2px solid red",
  },
  twoColumnFormHalf: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    // border: "2px solid black",
    marginInline: "1rem",
  },
};
