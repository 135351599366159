import { Box } from "@mui/material";
import { propertyFormStyles } from "components/styles";
import { usePropertyCreation } from "services/property-creation-context";
import { CheckboxInput, LabeledTextInput, TwoColumnForm } from "components";
import { getLocalizedLabels, useApp } from "services";
import { LABELS } from "../../constants";
import { type Property } from "@interfaces";
import { useParams } from "react-router-dom";
import { usePropertyEdit } from "services/property-edit-context";

export const StepEightForm = () => {
    const { id } = useParams();
    const labels = getLocalizedLabels(LABELS);
    const { user } = useApp();
    const {
        property,
        handleCheckboxChange,
        handleTextChange,
        validationErrors,
    } = id ? usePropertyEdit() : usePropertyCreation();

    return (
        <Box sx={propertyFormStyles.centeredContainer}>
            <TwoColumnForm
                leftSide={
                    <Box>
                        <LabeledTextInput
                            label={labels.name + "*"}
                            name="name"
                            placeholder={labels.name}
                            handleChange={handleTextChange}
                            value={
                                property.name
                                // user?.first_name + " " + user?.last_name
                            }
                            mb="1.5rem"
                            fullWidth
                            // disabled
                            tooltip={
                                "Wenn Sie Ihren Namen verbergen möchten, erscheint “Selbst-Vermarkten Member” als öffentlicher Name"
                            }
                            hasError={validationErrors.includes("name")}
                        />
                        <LabeledTextInput
                            label={labels.phone}
                            name="phone"
                            placeholder={labels.phone}
                            handleChange={handleTextChange}
                            value={property.phone}
                            mb="1.5rem"
                            tooltip="Viele Interessenten informieren sich gerne telefonisch, daher ist es empfehlenswert eine Telefonnummer für potentielle Kunden zu hinterlassen"
                            hasError={validationErrors.includes("phone")}
                            fullWidth
                        />
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            {["hide_name", "hide_email", "hide_phone"].map(
                                (hiddenProperty) => {
                                    return (
                                        <CheckboxInput
                                            key={hiddenProperty}
                                            name={hiddenProperty}
                                            label={labels[hiddenProperty]}
                                            checked={
                                                property[
                                                    hiddenProperty as keyof Property
                                                ] as boolean
                                            }
                                            handleChange={handleCheckboxChange}
                                            disabled={
                                                hiddenProperty === "hide_email"
                                            }
                                        />
                                    );
                                },
                            )}
                        </Box>
                    </Box>
                }
                rightSide={
                    <Box>
                        <LabeledTextInput
                            label={labels.email + "*"}
                            name="email"
                            placeholder={labels.email}
                            handleChange={handleTextChange}
                            value={
                                property.email
                                    ? property.email
                                    : user?.email || ""
                            }
                            mb="1.5rem"
                            fullWidth
                            tooltip="Ihre E-Mail-Adresse wird von uns nicht veröffentlicht. Benachrichtigungen erhalten Sie direkt über uns."
                            disabled
                        />
                    </Box>
                }
            />
        </Box>
    );
};
