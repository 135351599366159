import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { getPrivatePropertyListAPI, IS_DEV, useApp } from "services";
import { useNavigate } from "react-router-dom";
import { PrivatePropertySummary } from "components";
import { useEffect, useState } from "react";
import { Property } from "@interfaces";

const PropertyListPage = () => {
  const navigate = useNavigate();
  const [privateProperties, setPrivateProperties] = useState<Property[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const loggedInUser = localStorage.getItem("user");

  const fetchPrivateProperties = async () => {
    try {
      const res = await getPrivatePropertyListAPI();
      if (res) setPrivateProperties(res);
    } catch (e) {
      if (IS_DEV) console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchProperties = async () => {
      await fetchPrivateProperties();
    };
    fetchProperties();
  }, []);

  return (
    <>
      <Typography
        // variant="h4"
        // gutterBottom
        sx={{
          fontSize: { xs: "1.5rem", xl: "2rem" },
          paddingInline: ".5rem",
        }}
      >
        Meine Immobilien
      </Typography>
      {privateProperties && privateProperties.length ? (
        <Box>
          {privateProperties.map((p) => (
            <PrivatePropertySummary
              key={p.id}
              property={p}
              isOwner={p.user?.toString() === loggedInUser}
              refetchProperties={fetchPrivateProperties}
            />
          ))}
        </Box>
      ) : (
        <Box>
          {!loading ? (
            <>
              <Typography>
                Sie haben noch keine Immobilien inseriert.
              </Typography>
              <Button
                sx={{ textTransform: "none" }}
                onClick={() => navigate("/property/new")}
              >
                Jetzt inserieren
              </Button>
            </>
          ) : (
            <CircularProgress />
          )}
        </Box>
      )}
    </>
  );
};

export default PropertyListPage;
