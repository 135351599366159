import { Box, Button, Typography } from "@mui/material";
import { propertyFormStyles } from "components/styles";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export const CreationSuccess = () => {
    const navigate = useNavigate();
    const iconStyle = {
        marginTop: "5%",
        fontSize: "80px",
        color: "#4ce1b6",
    };
    return (
        <Box sx={propertyFormStyles.centeredContainer}>
            <Box sx={iconStyle}>
                <FaCheckCircle />
            </Box>
            <Typography
                sx={{
                    color: "#646777",
                    marginBottom: "15px",
                    fontSize: "24px",
                    lineHeight: "32px",
                }}
            >
                Vielen Dank für ihre Buchung
            </Typography>
            <Typography
                sx={{
                    color: "#646777",
                    marginBottom: "15px",
                    fontSize: "18px",
                    lineHeight: "24px",
                }}
            >
                {" "}
                Die Immobilie wurde erfolgreich inseriert.
            </Typography>
            <Button
                sx={{ textTransform: "none" }}
                onClick={() => {
                    navigate("/property/list");
                }}
            >
                Zum Dashboard
            </Button>
        </Box>
    );
};
