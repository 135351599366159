import { Box, Typography, useTheme } from "@mui/material";

export const StepTwelveLabel = ({
    value,
    additional_info,
    color,
}: {
    value: string;
    additional_info?: string;
    color?: string;
}) => {
    const { customColors } = useTheme();
    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
                variant="h5"
                sx={{
                    marginBlock: ".5rem",
                    textTransform: "none",
                    color: color ? color : customColors.colorPrimary,
                    // backgroundColor: backgroundColor || "#fff",
                }}
            >
                {value}
            </Typography>
            {additional_info && (
                <Typography sx={{ ml: ".25rem" }}>{additional_info}</Typography>
            )}
        </Box>
    );
};
