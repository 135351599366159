import { Box, Typography, useTheme } from "@mui/material";
import { type Property } from "@interfaces";
import { PropertyMenuStep } from "./PropertyMenuStep";
import {
    type CurrentStep,
    usePropertyCreation,
} from "services/property-creation-context";
import { useEffect } from "react";

interface PropertyCreationStepsMenuProps {
    property: Property;
    currentStep: number;
}
export const PropertyCreationStepsMenu = ({
    property,
    currentStep,
}: PropertyCreationStepsMenuProps) => {
    const { customShadows } = useTheme();
    const { STEPS, setCurrentStep, highestAllowedStep } = usePropertyCreation();
    const handleClick = (idx: CurrentStep) => {
        const highestAllowedIndex = (highestAllowedStep - 1) as CurrentStep;
        if (idx <= highestAllowedIndex) setCurrentStep(idx);
        else {
            setCurrentStep(highestAllowedIndex);
        }
    };
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Box
                sx={{
                    display: { xs: "none", md: "flex" },
                    backgroundColor: "#fff",
                    flexWrap: "wrap",
                    mb: "2px",
                }}
            >
                {STEPS.map((step) => {
                    return (
                        <PropertyMenuStep
                            key={step.idx}
                            idx={step.idx}
                            handleClick={handleClick}
                            label={`${step.label}`}
                            selected={step.idx <= currentStep}
                        />
                    );
                })}
            </Box>
            {currentStep != 9 && (
                <Box
                    sx={{
                        backgroundColor: "#fff",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        paddingBlock: { xs: "1rem", xl: "2rem" },
                        flexDirection: "column",
                    }}
                >
                    <Typography variant="h5" sx={{ textTransform: "none" }}>
                        {!currentStep
                            ? STEPS[0].label
                            : STEPS[currentStep].label}
                    </Typography>
                    <Typography
                        variant="h5"
                        sx={{
                            ml: ".5rem",
                            display: { xs: "block", md: "none" },
                            textTransform: "none",
                        }}
                    >
                        (Schritt {currentStep + 1}/10 )
                    </Typography>
                </Box>
            )}
        </Box>
    );
};
