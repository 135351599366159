import { SvgIconComponent } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import { PropertySummaryLabel } from "./PropertySummaryLabel";
import { PropertySummaryValue } from "./PropertySummaryValue";

export const PropertySummarySection = ({
    value,
    label,
    icon: Icon,
}: {
    label: string;
    value: string;
    icon?: SvgIconComponent;
}) => {
    const { customColors } = useTheme();
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center" }}>
                {Icon && (
                    <Icon
                        sx={{
                            fontSize: { xs: "1.2rem", sm: "1.5rem" },
                            color: customColors.colorPrimary,
                        }}
                    />
                )}
                <PropertySummaryLabel value={label} />
            </Box>
            <PropertySummaryValue value={value} />
        </Box>
    );
};
