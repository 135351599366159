import { Box, useTheme } from "@mui/material";
import { IS_DEV } from "services";
import { AppLogo } from "components";
import { useNavigate } from "react-router-dom";

export const TopbarNavLeftSide = (): JSX.Element => {
    const { customColors } = useTheme();
    const navigate = useNavigate();
    return (
        <Box
            sx={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                color: !IS_DEV ? "#000" : "#fff",
                // border: "8px solid green",
                "&:hover": !IS_DEV
                    ? {
                          backgroundColor: customColors.colorHover,
                      }
                    : {},
            }}
            onClick={() => {
                navigate("/");
            }}
        >
            <AppLogo />
        </Box>
    );
};
