import { typography } from "@mui/system";

export const labelStyles = {
    wrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    typography: {
        fontSize: { xs: "12px", md: "1rem" },
    },
};
