import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  CardMedia,
  CircularProgress,
  Avatar,
  Tooltip,
  useTheme,
} from "@mui/material";

import {
  FaEuroSign,
  FaExternalLinkAlt,
  FaFileWord,
  FaDoorOpen,
  FaClock,
  FaCar,
} from "react-icons/fa";
import {
  formatDate,
  handoverToAgentAPI,
  updatePropertyAPI,
  getLocalizedLabels,
  formatPrice,
  createExtraServicesAPI,
} from "services";
import { typographyStyles } from "components/styles";

import { LocationOnOutlined } from "@mui/icons-material";
import moment from "moment";
import { ExtraServicesForm } from "./PropertyExtraServicesForm";
import buyImg from "assets/images/buy.png";
import rentImg from "assets/images/rent.png";
import immoNetImg from "assets/images/App-Immonet.png";
import immoScoutImg from "assets/images/App-Immoscout24.png";
import immoWeltImg from "assets/images/App-Immowelt.png";

import { useNavigate } from "react-router-dom";
import { LABELS } from "./constants";
import { type Property } from "@interfaces";
import "./style.css";
import "scss/component/custom.css";
import { buttonStyles } from "components/styles/button";

import { CardRowItem, CardRow } from "./BasicComponents";
import {
  DisplayDialog,
  type DisplayDialogProps,
  defaultDisplayDialog,
} from "components/shared/DisplayDialog";
import { type ChangeInputEvent } from "@types";
import { isAxiosError } from "axios";
import { defaultPropertyImageUrl } from "services/img-utils";
import AppRouter, { MANAGE_SUBSCRIPTION_ROUTE } from "Router";
import { StepTwelveLabel } from "./forms/Step12/StepTwelveLabel";
import { PropertyStatusBadge } from "components/status-badge/PropertyStatusBadge";
import { isLessThanTwoMinutesInThePast } from "services/date-utils";

interface PrivatePropertySummaryProps {
  property: Property;
  isOwner: boolean;
  refetchProperties: () => Promise<void>;
}

export const PrivatePropertySummary = ({
  property,
  isOwner,
  refetchProperties,
}: PrivatePropertySummaryProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [dialog, setDialog] =
    useState<DisplayDialogProps>(defaultDisplayDialog);

  const imageUrl =
    property.images && property.images.length > 0
      ? property.images[0].url
      : defaultPropertyImageUrl;

  const togglePropertyActiveState = async () => {
    try {
      setLoading((_) => true);
      if (!property.id) return;
      await updatePropertyAPI(property.id, {
        ...property,
        is_active: !property.is_active,
      });
      await refetchProperties();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading((_) => false);
      openToggleActiveDialog();
    }
  };

  const handleCloseDialog = async () => {
    setDialog(defaultDisplayDialog);
  };

  const openToggleActiveDialog = () => {
    setDialog((prev) => ({
      ...prev,
      isOpen: true,
      id: "toggle_active",
      title: "Vielen Dank!",
      subtitle:
        "Bitte beachten Sie, dass eine Deaktivierung keine Auswirkung auf Ihr Abo hat. Um Ihr Abo zu kündigen, besuchen Sie bitte die Abo-Seite. Die Statusänderung wird in wenigen Minuten wirksam.",
      content: (
        <Box>
          <Button
            sx={{
              textTransform: "none",
              bgcolor: "#fff",
              color: "#000",
              mt: "1rem",
            }}
            onClick={goToProfile}
          >
            Abo verwalten
          </Button>
        </Box>
      ),
      confirmElement: (
        <Button sx={{ textTransform: "none" }} onClick={handleCloseDialog}>
          Schließen
        </Button>
      ),
    }));
  };

  const goToProfile = () => {
    navigate(`${MANAGE_SUBSCRIPTION_ROUTE}&propertyId=${property.id}`);
  };

  const getSubscriptionStatus = (): string => {
    const info = property.subscription_info;
    if (!info) return "N/A";
    return info.subscription_summary.status;
  };

  const getActiveText = () => {
    return "Aktiv";
  };

  const getActiveTooltipText = () => {
    const { subscription_info } = property;
    if (subscription_info) {
      if (subscription_info.subscription_summary.cancel_at_period_end)
        return "Kündigung vorgemerkt: Abo wird am Ende des Abrechnungszeitraums gekündigt";
      if (subscription_info.subscription_summary.pause_collection)
        return "Sie haben Ihr Abo pausiert. Es fallen keine weiteren Kosten an.";
      if (
        subscription_info.subscription_summary.status === "active" &&
        !property.immoscout_public_url
      ) {
        return "Bitte haben Sie noch einen Moment Geduld. Wir sind dabei, Ihr Inserat zu veröffentlichen.";
      }
      return "Abo aktiv";
    }
  };

  const isMarkedForCancelation =
    property.subscription_info &&
    property.subscription_info.subscription_summary.cancel_at_period_end;

  const isCancelled =
    property.subscription_info?.subscription_summary.status === "canceled";

  const getPropertyStatusDisplay = (): React.ReactNode => {
    if (getSubscriptionStatus() === "active" && property.immoscout_public_url) {
      return (
        <PropertyStatusBadge
          tooltipText={getActiveTooltipText()}
          content={getActiveText()}
          status="active"
        />
      );
    }
    if (
      !property.public_urls?.length &&
      isLessThanTwoMinutesInThePast(property.subscription_info?.created_on) &&
      getSubscriptionStatus() === "active"
    ) {
      return (
        <PropertyStatusBadge
          tooltipText={getActiveTooltipText()}
          content={"In Bearbeitung"}
          status="active"
        />
      );
    }
  };
  return (
    <Box>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          boxShadow: " 1px 4px 8px #aeaeae",
          "&:hover": {
            boxShadow: " 1px 8px 12px #aeaeae",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            fontWeight: 700,
            position: "relative",
            mt: { xs: "1rem", md: 0 },
            alignSelf: "flex-end",
          }}
        >
          {isMarkedForCancelation && !isCancelled && (
            <Tooltip
              title={
                <Typography>
                  Ihr Abo läuft am {""}
                  {property.subscription_info &&
                    formatDate(
                      property.subscription_info.subscription_summary
                        .current_period_end
                    )}{" "}
                  ab.
                </Typography>
              }
              arrow
            >
              <Typography
                sx={{
                  backgroundColor: "#cecece",
                  display: "flex",
                  justifyContent: "center",
                  padding: { xs: ".1rem .5rem", md: "1rem" },
                }}
              >
                Kündigung vorgemerkt
              </Typography>
            </Tooltip>
          )}
          {isCancelled && (
            <Tooltip
              title={
                <Typography>
                  Laufzeit-Ende:{" "}
                  {property.subscription_info &&
                    formatDate(
                      property.subscription_info.subscription_summary
                        .current_period_end
                    )}{" "}
                  ab.
                </Typography>
              }
              arrow
            >
              <Typography
                sx={{
                  padding: "1rem",
                  backgroundColor: "#cecece",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Abo Abgelaufen
              </Typography>
            </Tooltip>
          )}

          {!isCancelled &&
            property.subscription_info &&
            property.subscription_info?.subscription_summary.remaining_time && (
              <Typography
                sx={{
                  padding: { xs: ".1rem .5rem", md: "1rem" },
                  backgroundColor: "#4caf50",
                  display: "flex",
                  justifyContent: "center",
                  color: "#fff",
                  fontWeight: 700,
                  borderRadius: "4px",
                }}
              >
                {`Verbleibende Laufzeit: ${property.subscription_info.subscription_summary.remaining_time}`}
              </Typography>
            )}
        </Box>

        <CardContent
          sx={{ transform: { xs: "translateY(0)", md: "translateY(-4rem)" } }}
        >
          <h3>{property.title}</h3>
          <Box sx={{ display: "flex" }}>
            {property.archived && (
              <Typography
                sx={{
                  padding: "1rem",
                  backgroundColor: "#cecece",
                  width: "150px",
                  display: "flex",
                  justifyContent: "center",
                  mr: "1rem",
                }}
              >
                Archiviert
              </Typography>
            )}

            {getPropertyStatusDisplay()}

            <Box
              sx={{
                mr: "1rem",
                display: "flex",
                alignItems: "center",
                gap: ".5rem",
                ml: "1rem",
              }}
            >
              {property.immonet_public_url && (
                <Avatar
                  alt="Immonet"
                  src={immoNetImg}
                  className={"platform-logo"}
                />
              )}

              {property.immoscout_public_url && (
                <Tooltip
                  title="Inserat auf immoscout24 ansehen"
                  followCursor
                  arrow
                >
                  <Avatar
                    alt="ImmoScout"
                    src={immoScoutImg}
                    className={"platform-logo"}
                    onClick={() => {
                      property.immoscout_public_url &&
                        window.open(property.immoscout_public_url);
                    }}
                  />
                </Tooltip>
              )}

              {property.immowelt_public_url && (
                <Tooltip
                  title="Inserat auf immowelt ansehen"
                  arrow
                  followCursor
                >
                  <Avatar
                    alt="ImmoWelt"
                    src={immoWeltImg}
                    className={"platform-logo"}
                    onClick={() => {
                      property.immowelt_public_url &&
                        window.open(property.immowelt_public_url);
                    }}
                  />
                </Tooltip>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                md: "row",
              },
            }}
          >
            <CardMedia
              component="img"
              image={imageUrl}
              alt="PropertyView"
              sx={{
                maxWidth: { xs: "100%", md: "150px" },
                maxHeight: "150px",
                borderRadius: "4px",
              }}
            />

            <Box
              sx={{
                flex: 1,
              }}
            >
              {/* Title Row */}
              <CardRow sx={{ mb: "1rem", ml: 0 }}>
                <LocationOnOutlined
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "16px",
                      md: "32px",
                    },
                  }}
                />
                <Typography sx={typographyStyles.card_title}>
                  {`${property.city}, ${property.post_code}, ${property.country}`}
                </Typography>
              </CardRow>
              <CardRow>
                <CardRowItem>
                  <FaEuroSign />{" "}
                  {property.transaction_type === "sell"
                    ? "Kaufpreis"
                    : "Mietpreis"}
                  : {formatPrice(property.price || property.base_rent || 0)}
                </CardRowItem>{" "}
                <CardRowItem>
                  <FaExternalLinkAlt /> Wohnfläche: {property.living_space}
                </CardRowItem>
              </CardRow>
              <CardRow>
                <CardRowItem>
                  <FaFileWord /> Listing-ID:
                  {property.id ? 1000 + property.id : "N/A"}
                </CardRowItem>{" "}
                <CardRowItem>
                  <FaDoorOpen /> Anzahl Zimmer: {property.room_count}
                </CardRowItem>
              </CardRow>
              <CardRow>
                <CardRowItem>
                  <FaClock /> Erstellt: {formatDate(property.created_on)}
                </CardRowItem>{" "}
                <CardRowItem>
                  <FaCar /> Anzahl Stellplätze: {property.garage_count}
                </CardRowItem>
              </CardRow>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <Button
              sx={{
                ...buttonStyles.privateProperty,
                flex: 1,
                maxWidth: { xs: "100%", sm: "50%", md: "32%" },
                display: property.archived ? "none" : "block",
              }}
              disabled={property.archived}
              onClick={() => navigate(`/property/edit/${property.id}`)}
            >
              Bearbeiten
            </Button>

            <Button
              sx={{
                ...buttonStyles.privateProperty,
                flex: 1,
                display: property.archived ? "none" : "block",
                maxWidth: { xs: "100%", sm: "50%", md: "32%" },
              }}
              disabled={property.archived}
              onClick={goToProfile}
            >
              Abo ansehen
            </Button>
            <Tooltip
              title={
                property.is_active
                  ? "Bitte beachten Sie, dass eine Deaktivierung keine Auswirkung auf Ihr Abo hat. Um Ihr Abo zu kündigen, besuchen Sie bitte die Abo-Seite."
                  : ""
              }
            >
              <Button
                sx={{
                  ...buttonStyles.privateProperty,
                  flex: 1,
                  maxWidth: { xs: "100%", sm: "50%", md: "32%" },
                }}
                disabled={isCancelled || !property.is_active}
                onClick={togglePropertyActiveState}
              >
                {loading ? (
                  <CircularProgress />
                ) : !property.is_active ? (
                  "Aktivieren"
                ) : (
                  "Deaktivieren"
                )}
              </Button>
            </Tooltip>
            <Button
              sx={{
                ...buttonStyles.privateProperty,
                flex: 1,
                maxWidth: { xs: "100%", sm: "50%", md: "32%" },
              }}
              onClick={() => {
                navigate(`/property/detail/${property.id}`);
              }}
            >
              Details Anzeigen
            </Button>
          </Box>
        </CardContent>
      </Card>

      <DisplayDialog {...dialog} />
    </Box>
  );
};
