export const BASE_ADMIN_URL = process.env.REACT_APP_ADMIN_URL;
export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
export const BASE_AWS_S3_URL = process.env.REACT_APP_BASE_AWS_S3_URL;
export const IS_DEV = process.env.REACT_APP_IS_TEST === "true";

if (process.env.NODE_ENV === "development") {
    console.info("set react app env var BASE_API_URL", BASE_API_URL);
    console.info("set react app env var BASE_AWS_S3_URL", BASE_AWS_S3_URL);
    console.info("set react app env var BASE_API_URL", IS_DEV);
}
