import { FaTelegramPlane } from "react-icons/fa";
import { Box, Typography, useTheme } from "@mui/material";
import { type Package } from "@interfaces";
import { usePropertyCreation } from "services/property-creation-context";
import { TransactionType } from "enums";
import { formatPackagePrice, formatPrice } from "services";
import { SelectButton } from "components/shared/SelectButton";
export const PackageCard = ({
    packageData,
    isSelected,
    handleClick,
}: {
    packageData: Package;
    isSelected: boolean;
    handleClick: (packageId: number) => void;
}) => {
    const { customColors } = useTheme();
    const { colorPrimary } = customColors;

    return (
        <Box
            sx={{
                borderRadius: ".5rem",
                border: !isSelected
                    ? `1px solid ${colorPrimary}`
                    : "1px solid green",
                borderTop: !isSelected
                    ? `8px solid ${colorPrimary}`
                    : "8px solid green",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "1rem",
                cursor: "pointer",
                flex: 1,
                minWidth: "300px",
                margin: { xs: ".5rem", md: "1rem" },
            }}
            onClick={() => handleClick(packageData.id)}
        >
            <FaTelegramPlane
                style={{
                    fontSize: "3rem",
                    color: isSelected ? "green" : colorPrimary,
                }}
            />
            <Typography
                sx={{
                    fontWeight: 700,
                    color: "#000",
                    fontSize: { xs: "14px", md: "1.2rem" },
                }}
            >
                {packageData.name}
            </Typography>

            <Typography variant="h6">
                {formatPackagePrice(packageData["price"] as number)}
            </Typography>
            <Typography sx={{ fontSize: ".9rem", padding: "1rem" }}>
                {packageData.description}
            </Typography>
            <SelectButton label={"Auswählen"} handleClick={() => {}} />
        </Box>
    );
};
