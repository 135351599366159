import axios, { AxiosHeaders, type AxiosError } from "axios";

import { BASE_API_URL } from "./constants";
import {
  AdminProperty,
  type Coupon,
  type Enquiry,
  type Package,
  type Property,
  type PropertyCreateDto,
} from "@interfaces";
import { createPropertyDto } from "./apiUtils";
import { Message } from "interfaces/message.interface";
import { exec } from "child_process";

const _getWithHeader = async <T,>(url: string): Promise<T | void> => {
  try {
    const { data } = await axios.get<T>(url, {
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    });
    return data;
  } catch (error: any) {
    console.error(error.response.data);
  }
};

type CustomAxiosHeaders = {
  "Content-Type": string;
  [header: string]: string | undefined;
};

const _postWithHeader = async <T,>(
  url: string,
  payload: T,
  headers?: CustomAxiosHeaders
): Promise<T | AxiosError> => {
  try {
    const { data } = await axios.post<T>(url, payload, {
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        ...headers,
      },
    });
    return data;
  } catch (error: any) {
    console.error(error?.response?.data);
    return error as AxiosError;
  }
};

const _postWithHeaderAllowVoid = async <T,>(
  url: string,
  payload: T,
  headers?: CustomAxiosHeaders
): Promise<T | void> => {
  const { data } = await axios.post<T>(url, payload, {
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
      ...headers,
    },
  });
  if (data) return data;
};

const _deleteWithHeader = async <T,>(url: string): Promise<T | AxiosError> => {
  try {
    const { data } = await axios.delete<T>(url, {
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    });
    return data;
  } catch (error: any) {
    console.error(error?.response?.data);
    return error as AxiosError;
  }
};

const _patchWithHeader = async <T,>(
  url: string,
  payload: PropertyCreateDto
): Promise<T | AxiosError> => {
  try {
    const { data } = await axios.patch<T>(url, payload, {
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    });
    return data;
  } catch (error: any) {
    console.error(error?.response?.data);
    throw error;
  }
};

export const getPrivatePropertyListAPI = async (): Promise<
  Property[] | void
> => {
  const url = `${BASE_API_URL}/properties/`;
  const res = await _getWithHeader<Property[]>(url);
  return res;
};

export const searchPropertyListAPI = async (
  params: URLSearchParams
): Promise<{ properties: Property[]; total_pages: number } | void> => {
  const url = `${BASE_API_URL}/properties/search/?${params.toString()}`;

  const res = await _getWithHeader<{
    data: any[];
    total_pages: number;
  }>(url);
  if (res) {
    return {
      properties: res.data.map((p) => ({
        ...p,
        // images: p.images.map((img: any) => img.url),
      })),
      total_pages: res.total_pages,
    };
  }
};

export const getPackageListAPI = async (): Promise<Package[] | void> => {
  const url = `${BASE_API_URL}/properties/packages/`;
  const res = await _getWithHeader<Package[]>(url);
  return res;
};

export const getCouponListAPI = async (): Promise<Coupon[] | void> => {
  const url = `${BASE_API_URL}/payments/coupons/`;
  const res = await _getWithHeader<Coupon[]>(url);
  return res;
};

export const createPropertyAPI = async (
  payload: PropertyCreateDto
): Promise<Property> => {
  const url = `${BASE_API_URL}/properties/`;
  const parsedPayload = createPropertyDto(payload);
  const res = await _postWithHeader(url, parsedPayload);
  return res;
};

export const createPropertyPackageAPI = async (
  payload: PropertyCreateDto,
  createdPropertyId: number
): Promise<{ url: string }> => {
  const url = `${BASE_API_URL}/properties/create-package/`;
  const parsedPayload = createPropertyDto(payload);
  const res = await _postWithHeader(url, {
    ...parsedPayload,
    id: createdPropertyId,
  });
  return res;
};

export const getSignedUrlAPI = async (file_names: string[]): Promise<any> => {
  const url = `${BASE_API_URL}/common/signed-url/`;
  const res = await _postWithHeader(url, { file_names });
  return res;
};

export const sendTestMail = async (): Promise<any> => {
  const url = `${BASE_API_URL}/common/send-test-mail/`;
  const res = await _postWithHeader(url, { test: "test" });
  return res;
};

// export const uploadPropertyImagesAPI = async (images_array: FormData[]) => {
//     const url = `${BASE_API_URL}/common/upload-images/`;
//     try {
//         console.log("uploading images xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");
//         const res = await _postWithHeader(url, images_array, {
//             "Content-Type": "multipart/form-data",
//         });
//         return res;
//     } catch (error) {
//         console.error(error);
//         throw new Error("Error processing images");
//     }
// };

export const deletePropertyImageAPI = async (id: string) => {
  const url = `${BASE_API_URL}/properties/delete-image/${id}/`;
  try {
    await _deleteWithHeader(url);
  } catch (error: any) {
    console.error(error);
  }
};

export const updatePropertyAPI = async (
  id: number,
  payload: PropertyCreateDto
) => {
  const url = `${BASE_API_URL}/properties/${id}/update/`;
  const data = await _patchWithHeader(url, payload);
  return data;
};

export const isOwner = (property: Property) => {
  const loggedInUser = Number(localStorage.getItem("user"));

  return loggedInUser === property.user;
};

export const getPropertyAPI = async (id: string) => {
  const url = `${BASE_API_URL}/properties/${id}/`;
  const property = await _getWithHeader<any | void>(url);
  return property;
};
export const getPublicPropertyAPI = async (id: string) => {
  const url = `${BASE_API_URL}/properties/public/${id}/`;
  const property = await _getWithHeader<any | void>(url);
  return property;
};

export const createCustomerEnquiryAPI = async (
  payload: Enquiry
): Promise<any> => {
  const url = `${BASE_API_URL}/properties/enquiry/`;
  const res = _postWithHeader<Enquiry | void>(url, payload);
  if (res) return await res;
};

export const createExtraServicesAPI = async (payload: number[]) => {
  const url = `${BASE_API_URL}/properties/requests/`;
  const res = await _postWithHeader(url, payload);
  if (res) return res;
};

export const handoverToAgentAPI = async (id: number): Promise<any> => {
  const url = `${BASE_API_URL}/properties/handover-to-agent/`;
  const res = await _postWithHeader(url, { property_id: id });
  return res;
};

export const deleteAllTestDataAPI = async (): Promise<any> => {
  if (process.env.REACT_APP_IS_TEST !== "true") return;
  try {
    const url = `${BASE_API_URL}/common/delete-all-test-data/`;
    const res = await _deleteWithHeader(url);
    return res;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const deleteAllEstateSyncPropertiesAndListingsAPI =
  async (): Promise<any> => {
    if (process.env.REACT_APP_IS_TEST !== "true") return;
    try {
      const url = `${BASE_API_URL}/common/delete-estate-sync-test-data/`;
      const res = await _deleteWithHeader(url);
      return res;
    } catch (error: any) {
      throw new Error(error);
    }
  };

export const getAllSyncedPropertiesAPI = async (): Promise<any> => {
  // if (process.env.REACT_APP_IS_TEST === "false") return [];
  try {
    const url = `${BASE_API_URL}/properties/list-all-synced-properties`;
    const res = await _getWithHeader(url);
    return res;
  } catch (error: any) {
    throw new Error(error);
  }
};
export const toggleEstateSyncAPI = (id: string) => {
  try {
    const url = `${BASE_API_URL}/properties/toggle-estate-sync/${id}`;
    return _postWithHeader(url, {});
  } catch (error: any) {
    throw new Error(error);
  }
};

export const searchPropertiesAsAdminAPI = async (
  query: string
): Promise<AdminProperty[] | void> => {
  const url = `${BASE_API_URL}/properties/admin-search/?${query}`;
  const res = await _getWithHeader<AdminProperty[]>(url);
  if (res) return res;
};

export const deactivatePropertyAPI = async (
  payload: AdminProperty
): Promise<AdminProperty | void> => {
  const url = `${BASE_API_URL}/properties/admin-deactivate/${payload.id}`;
  const res = await _postWithHeaderAllowVoid<AdminProperty>(url, payload);
  if (res) return res;
};

export const uploadPropertyImagesAPI = async (formData: FormData) => {
  const url = `${BASE_API_URL}/common/upload-images/`;
  try {
    const res = await _postWithHeader(url, formData, {
      "Content-Type": "multipart/form-data",
    });
    return res;
  } catch (error) {
    console.error(error);
    throw new Error("Error processing images");
  }
};
