import { Button, useTheme } from "@mui/material";
import { buttonStyles } from "components/styles";
import { useSearchParams } from "react-router-dom";

export const SelectButton = ({
    fullWidth,
    label,
    handleClick,
    disabled,
    selected,
    hasError,
}: {
    label: string;
    handleClick: () => void;
    fullWidth?: boolean;
    disabled?: boolean;
    selected?: boolean;
    hasError?: boolean;
}) => {
    const { customColors } = useTheme();
    const [searchParams] = useSearchParams();
    return (
        <Button
            onClick={handleClick}
            sx={{
                ...buttonStyles.select,
                width: fullWidth ? "100%" : "auto",
                marginInline: fullWidth ? 0 : "1rem",
                border: hasError
                    ? `3px solid ${customColors.colorError}`
                    : `2px solid ${customColors.colorPrimary}`,
                color: selected ? "#fff" : customColors.colorPrimary,

                backgroundColor: selected ? customColors.colorPrimary : "#fff",

                "&:hover": {
                    unset: "all",
                    backgroundColor: customColors.colorPrimary,
                    color: "#fff",
                },
                "&:disabled": {
                    backgroundColor: selected ? customColors.colorPrimary : "",
                    color: selected ? "#fff" : "",
                },
            }}
            disabled={disabled || false}
        >
            {label}
        </Button>
    );
};
