import { Box, Typography } from "@mui/material";
import LightLogo from "assets/images/logo_light.png";
import DarkLogo from "assets/images/logo_dark.png";
import { IS_DEV } from "services";
export const AppLogo = ({ darkMode = false }: { darkMode?: boolean }) => {
    return (
        <Typography
            sx={{
                // width: "150px",
                margin: "auto 0",
                backgroundRepeat: "no-repeat",
                backgroundPositionY: "center",
                backgroundPositionX: "left",
                backgroundSize: "contain",
                ml: "1rem",
                fontSize: { xs: "12px", sm: "20px" },
            }}
        >
            {`${IS_DEV ? "Test." : ""}Selbst-Vermarkten.de`}
        </Typography>
    );
};
