import { Property, type PreviewImage } from "interfaces";
import { getSignedUrlAPI, uploadPropertyImagesAPI } from "./properties";

export const getPreviewUrls = async (
  previewImages: PreviewImage[]
): Promise<PreviewImage[] | void> => {
  if (!previewImages) return;

  const imageNames = previewImages.map((previewImg) => previewImg.file.name);
  const signedResponses: any = await getSignedUrlAPI(imageNames);

  signedResponses.data.forEach((signedResponse: String, index: number) => {
    console.log("res", signedResponse);
    previewImages[index].uploadFields = signedResponse;
  });
  console.log("here", previewImages);

  return previewImages;
};

// export const uploadImagesToAWS = async (
//     previewImages: PreviewImage[],
//     property: Property,
// ) => {
//     try {
//         const numberOfExistingImages = property.images.length;
//         const promises: Promise<any>[] = [];
//         previewImages.forEach((image, idx) => {
//             const order = numberOfExistingImages + idx;

//             const formData = new FormData();
//             formData.append("image", image.file);
//             formData.append("image_name", image.file.name);
//             if (!property.id) {
//                 throw new Error("Property ID not found");
//             }
//             formData.append("property_id", property.id.toString());
//             formData.append("order", order.toString());
//             const promise = uploadPropertyImagesAPI(formData);
//             promises.push(promise);
//         });
//         const res = await Promise.all(promises);

//         return res;
//     } catch (error) {
//         console.error(error);
//         throw new Error("Error uploading images");
//     }
// };

export const defaultPropertyImageUrl =
  "https://property-default-images.s3.eu-central-1.amazonaws.com/house.jpg";

// export const uploadImagesToAWS = async (
//     previewImages: PreviewImage[],
//     property: Property,
// ) => {
//     try {
//         console.log('tryyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy')
//         const numberOfExistingImages = property.images.length;
//         const imagesData = previewImages.map((image, idx) => {
//             const order = numberOfExistingImages + idx;

//             const formData = new FormData();
//             formData.append("image", image.file);
//             formData.append("image_name", image.file.name);
//             if (!property.id) {
//                 throw new Error("Property ID not found");
//             }
//             formData.append("property_id", property.id.toString());
//             formData.append("order", order.toString());

//             return formData;
//         });

//         const res = await uploadPropertyImagesAPI(imagesData);

//         return res;
//     } catch (error) {
//         console.error(error);
//         throw new Error("Error uploading images");
//     }
// };

export const uploadImagesToAWS = async (
  previewImages: PreviewImage[],
  property: Property
) => {
  try {
    const numberOfExistingImages = property.images.length;
    const formData = new FormData();

    previewImages.forEach((image, idx) => {
      const order = numberOfExistingImages + idx;

      formData.append(`images[${idx}][image]`, image.file);
      formData.append(`images[${idx}][image_name]`, image.file.name);
      if (!property.id) {
        throw new Error("Property ID not found");
      }
      formData.append(`images[${idx}][property_id]`, property.id.toString());
      formData.append(`images[${idx}][order]`, order.toString());
    });

    console.log("this is the ", formData);

    const res = await uploadPropertyImagesAPI(formData);

    return res;
  } catch (error) {
    console.error(error);
    throw new Error("Error uploading images");
  }
};
