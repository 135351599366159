import { Box, Typography } from "@mui/material";

export const PropertySummaryLabel = ({
    value,
    tooltip,
}: {
    value: string;
    tooltip?: string;
}) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
            }}
        >
            <Typography
                variant="h5"
                sx={{ marginBlock: ".5rem", textTransform: "none" }}
            >
                {value}
            </Typography>
        </Box>
    );
};
