import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { CreationSuccess } from "components/properties/CreationSuccess";
import { propertyFormStyles } from "components/styles";
import { FaCheckCircle } from "react-icons/fa";

export const PropertySubscriptionUpdatedPage = () => {
    const navigate = useNavigate();
    const iconStyle = {
        marginTop: "5%",
        fontSize: "80px",
        color: "#4ce1b6",
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={propertyFormStyles.centeredContainer}>
                <Box sx={iconStyle}>
                    <FaCheckCircle />
                </Box>
                <Typography
                    sx={{
                        color: "#646777",
                        marginBottom: "15px",
                        fontSize: "24px",
                        lineHeight: "32px",
                    }}
                >
                    Vielen Dank!
                </Typography>
                <Typography
                    sx={{
                        color: "#646777",
                        marginBottom: "15px",
                        fontSize: "18px",
                        lineHeight: "24px",
                    }}
                >
                    {" "}
                    Ihr Abo wurde erfolgreich aktualisiert.
                </Typography>
                <Button
                    sx={{ textTransform: "none" }}
                    onClick={() => {
                        navigate("/property/list");
                    }}
                >
                    Zum Dashboard
                </Button>
            </Box>
        </Box>
    );
};
