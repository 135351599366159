import { TopbarNav } from "./TopbarNav";
import { Box } from "@mui/material";

interface ProtectedRouteNavigationWrapperProps {
    children: React.ReactNode;
}
export const ProtectedRouteNavigationWrapper = ({
    children,
}: ProtectedRouteNavigationWrapperProps) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                boxSizing: "border-box",
            }}
        >
            <TopbarNav />
            <Box
                sx={{
                    paddingTop: { xs: "0rem", md: "2rem" },
                    paddingInline: { xs: "0rem", md: "3rem" },
                }}
            >
                {children}
            </Box>
        </Box>
    );
};
