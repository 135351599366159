import { Box, Tooltip, Typography } from "@mui/material";

export const PropertySummaryValue = ({
    value,
    tooltip,
}: {
    value: string;
    tooltip?: string;
}) => {
    return (
        <Tooltip title={tooltip} arrow>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                    sx={{
                        // marginBlock: ".5rem",
                        textTransform: "none",
                        fontSize: { xs: ".9rem", md: "1.2rem" },
                        fontWeight: 550,
                        // color: "#000",
                    }}
                >
                    {value}
                </Typography>
            </Box>
        </Tooltip>
    );
};
